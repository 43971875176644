const enums = {
    language: {
        cn: 'cn',
        en: 'en'
    },
    deviceType: {
        PC: 'pc',
        MOBILE: 'mobile'
    }
}

export {
    enums
}