
<template>
    <img :id=this.soleId class="rolebox" :src=roleSrc>
</template>

<script>
export default {
    props: {
        roleSrc: String,
        soleId: String,
        initleft: Number,
    },
    methods: {
        moveloop() {

            let left = 0;
            let rotateTag = 0;
            let soleId = this.soleId;
            let inittag = true;
            let initleft = this.initleft;
            let runwidth = document.body.clientWidth > 1260 ? document.body.clientWidth : 1260
            setInterval(function () {
                left += ((rotateTag ? -1 : 1) * 2.5);
                let role = document.getElementById(soleId);
                if (inittag) { left = initleft; inittag = false; }

                role.style.left = (left + 'px');
                if (left >= runwidth) {
                    role.style.transform = 'rotateY(180deg)';
                    rotateTag = 1;
                } else if (left <= 0) {
                    role.style.transform = 'rotateY(0deg)';
                    rotateTag = 0;
                }
            }, 100);
        }
    },
    created() {
        this.moveloop();
    }
}
</script>
<style>
.rolebox {
    position: absolute;
    top: 53px;
}
</style>