import MIndex from '../view/m_index.vue'
import PcIndex from '../view/pc_index.vue'

const routes = [
    {
        path: '',
        redirect: '/pc_index'
    },
    {
        path: "/pc_index", // pc端首页
        name: PcIndex,
        component: PcIndex
    },
    {
        path: '/m_index', // 手机端首页
        name: MIndex,
        component: MIndex
    }
]

export default routes