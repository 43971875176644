
<template>
    <div class="card" :id="this.setKey('card', this.id)">
        <img class="cardimg" :id="this.setKey('cardimg', this.id)" :src="banner" alt="Hot air balloons">
        <h1 :id="this.setKey('h1', this.id)">《{{ title }}》</h1>
        <p :id="this.setKey('p', this.id)">{{ description }}</p>
        <div class="btnlist" :id="this.setKey('btnlist', this.id)">
            <a target="_blank" :href=appstoreKey><img :id="this.setKey('downloadbtn-1', this.id)" v-if="appstoreKey"
                    :src="iosbtnSrc" class="downloadbtn"></a>
            <a target="_blank" :href=androidKey><img :id="this.setKey('downloadbtn-2', this.id)" v-if="androidKey"
                    :src="googlebtnSrc" class="downloadbtn"></a>
        </div>
    </div>
    <div :id="this.setKey('bar', this.id)">
    </div>
</template>

  
<script>
import { enums } from '@/global';
export default {
    name: "GameBox",
    props: {
        id: String,
        title: String,
        banner: String,
        description: String,
        appstoreKey: String,
        androidKey: String,
        iosbtnSrc: String,
        googlebtnSrc: String,
        deviceType: enums.deviceType
    },
    methods: {
        setKey(type, key) {
            return type + '_' + key;
        },
        getKey(type) {
            return type + '_' + this.id;
        },
        getMultiValue(data, key) {
            return data[key + '_' + this.language];
        },
        bindSytle() {
            if (this.deviceType === enums.deviceType.PC) {

                let card = document.getElementById(this.getKey('card'));
                card.style.height = '370px';

                //let sheet = card.sheet;

                // Use addRule or insertRule to inject styles
                //sheet.insertRule('.card::after { content: "",margin: 0 16px; background: #ededed;height: 1px; left: 0;  bottom: 62px; width:320px;position: relative;  }', 0);

            } else if (this.deviceType === enums.deviceType.MOBILE) {

                let card = document.getElementById(this.getKey('card'));
                card.style.paddingTop = '.2rem';

                let cardimgcard = document.getElementById(this.getKey('cardimg'));
                cardimgcard.style.width = '7rem';
                cardimgcard.style.height = '3.7rem';
                cardimgcard.style.position = 'relative';
                cardimgcard.style.left = '.25rem';

                let h1 = document.getElementById(this.getKey('h1'));
                h1.style.marginLeft = '.1rem';
                h1.style.height = '1rem';
                h1.style.width = '6rem';

                let p = document.getElementById(this.getKey('p'));
                p.style.marginTop = '.1rem';
                p.style.marginLeft = '0rem';
                p.style.paddingLeft = '.3rem';
                p.style.paddingRight = '0rem';
                p.style.width = '7rem';

                let btnlist = document.getElementById(this.getKey('btnlist'));
                btnlist.style.top = '0.1rem';
                btnlist.style.marginLeft = '0.1rem';
                btnlist.style.height = '1.8rem';


                let btnimg_1 = document.getElementById(this.getKey('downloadbtn-1'));
                if (btnimg_1) {
                    btnimg_1.style.width = '3.3rem';
                    //btnimg_1.style.paddingRight = '.1rem';
                    btnimg_1.style.height = '1.1rem';
                }

                let btnimg_2 = document.getElementById(this.getKey('downloadbtn-2'));
                if (btnimg_2) {
                    btnimg_2.style.width = '3.3rem';
                    btnimg_2.style.height = '1.1rem';
                }

                let bar = document.getElementById(this.getKey('bar'));
                bar.style.height = '.2rem';
                bar.style.backgroundColor = 'rgb(99 99 99 / 15%)';

            }
        }
    },
    mounted() {
        this.bindSytle();
    }
}
</script>

<style>
.card {
    display: grid;

    background-color: rgb(255, 255, 255);
    vertical-align: top;
}

.card:after {
    content: "";
    margin: 0 16px;
    background: #ededed00;
    height: 1px;
    left: 0;
    bottom: 62px;
    width: 95px;
    position: relative;
}

.cardimg {
    object-fit: cover;
    width: 100%;
    height: 200px;
    text-align: center;
}

.card h1 {
    text-align: left;
    height: 30px;
    margin-left: 15px;
    margin-bottom: -10px;
    margin-top: 2px;
    font-size: 15pt;
}

.card p {
    font-size: 11pt;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: rgb(173, 173, 173);
}

.btnlist {
    position: relative;
    top: 8px;
    margin-left: 20px;
    height: 52px;
}

.downloadbtn {
    margin: 3px;
    width: 150px;
    height: 50px;
}
</style>