<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'app',
  data() {
    return {
    }
  },
  components: {

  },
  created() {
    //获取html
    var html = document.getElementsByTagName("html")[0];
    if (window.innerWidth < 768) {
      //首次进入时获取屏幕宽度，宽度/750*100，手机端设计稿宽一般为750px，这里以750为基准调整基础大小，即当屏幕正好为750px时html字体大小为100px，p{font-size: 1rem}字体大小就为100px，屏幕宽为375px时，html50px、p50px，这里标签的宽高同样能使用rem表示
      html.style.fontSize = window.innerWidth / 750 * 100 + "px";
    } else {
      html.style.fontSize = "16px"
    }
    //实时获取改变html根元素字体大小
    window.addEventListener('resize', function () {
      if (window.innerWidth < 768) {
        html.style.fontSize = window.innerWidth / 750 * 100 + "px";
        // console.log(window.innerWidth/750*100 + "px");
      } else {
        html.style.fontSize = "16px"
      }
    })
  },
  mounted() {
    if (this._isMobile()) {
      this.$router.replace('/m_index');
    } else {
      this.$router.replace('/pc_index');
    }
  },
  methods: {
    _isMobile() {
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    }
  }

};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

#app {
  -webkit-text-size-adjust: none;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  -moz-text-size-adjust: 100% !important;
}
</style>
