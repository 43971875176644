<template>
  <div class="topbg">
    <img class="log_s" src="../assets/tag_studioLogo.png">
    <h1>Chowchow&CWGame Studio</h1>
    <a>{{ this.getMultiValue(multiStaticWord, keys.WELCOME) }}</a>
    <select @change="onselect($event)">
      <option v-for="item in selectlanguage" v-bind:key="item.id" v-bind:value="item.id" v-text="item.name"></option>
    </select>
  </div>
  <div class="gamegrid">

    <div v-for="(item, index) in games" :key="index">
      <GameBox :title="this.getMultiValue(item, keys.NAME)" :banner="this.getMultiValue(item, keys.BANNER)"
        :description="this.getMultiValue(item, keys.DESCRIPTION)" :appstoreKey=item.appstoreKey
        :iosbtnSrc="this.getMultiValue(gameboxsrc, keys.IOSBTNSRC)"
        :googlebtnSrc="this.getMultiValue(gameboxsrc, keys.GOOGLEBTNSRC)"
        :androidKey="this.getMultiValue(item, keys.ANDROIDKEY)" :deviceType="keys.MOBILE" :id="index">
      </GameBox>
    </div>
  </div>
  <div class="copyright">
    <div class="nie-cn-part2">
      <div>
        <img src="~@/assets/images/common_tag_mail.png">
        <a target="_blank">&nbsp; dsyxabc@gmail.com</a>
      </div>
      <p class="p-bottom">{{ this.getMultiValue(multiStaticWord, keys.BOTTOM_1) }}</p>
      <p class="p-bottom">{{ this.getMultiValue(multiStaticWord, keys.BOTTOM_2) }}</p>
      <p class="p-bottom">&nbsp;&nbsp;Copyright Nanjing Sailai Network Technology Co., Ltd
        ©2014-2023</p>
    </div>
  </div>
  <div class="bottomlogo">
    <img src="~@/assets/images/bottom_logo_cocos.png">
    <img id="unity" src="~@/assets/images/bottom_logo_unity.png">
    <img src="~@/assets/images/bottom_logo_ts.png">
    <img id="oc" src="~@/assets/images/bottom_logo_oc.png">
    <img id="java" src="~@/assets/images/bottom_logo_java.png">
    <img id="csharp" src="~@/assets/images/bottom_logo_csharp.png">
    <img id="python" src="~@/assets/images/bottom_logo_python.png">
    <img id="github" src="~@/assets/images/bottom_logo_github.png">
    <img id="springboot" src="~@/assets/images/bottom_logo_springboot.png">
    <img id="csharp" src="~@/assets/images/bottom_logo_lua.png">
    <img id="procreate" src="~@/assets/images/bottom_logo_procreate.png">
    <img id="dragonbones" src="~@/assets/images/bottom_logo_dragonbones.png">
  </div>
</template>

<script>

import GameBox from '../components/GameBox.vue';
import { getCurrentInstance } from "vue";
import { enums } from '@/global';
export default {
  name: "CWGames&ChowChow",
  props: {
    msg: String
  },
  data() {
    return {
      games: [
        {
          name_cn: "永恒传说-问世篇",
          name_en: "Eternal legend",
          banner_cn: require("../assets/images/el1-bg.jpg"),
          banner_en: require("../assets/images/el1-bg.jpg"),
          description_cn: "工作室早年的第一部作品，非常经典的单机回合制剧情向RPG，在武侠的世界放飞自我吧。",
          description_en: "The studio's early works, a very classic single-player turn-based plot to RPG, let yourself go in the world of martial arts.",
          appstoreKey: "https://cn.bing.com",
          androidKey_cn: "https://share.weiyun.com/J4i94BuN",
          androidKey_en: "https://play.google.com/store/apps/details?id=com.njsl.el1",
        },
        {
          name_cn: "永生劫-降魔篇",
          name_en: "The disaster of immortal",
          banner_cn: require("../assets/images/el2-bg.jpg"),
          banner_en: require("../assets/images/el2-bg.jpg"),
          description_cn: "工作室的第二部作品，这是一款仙侠风格的游戏，同样也是经典的回合制剧情向RPG。",
          description_en: "The studio's second work, this is a fairytale-style game, which is also a classic turn-based story RPG.",
          appstoreKey: "https://itunes.apple.com/app/id1079800951",
          androidKey_cn: "https://play.google.com/store/apps/details?id=com.njsl.el2",
          androidKey_en: "https://play.google.com/store/apps/details?id=com.njsl.el2",
        },
        {
          name_cn: "冒险之巅",
          name_en: "Fall of the divinities",
          banner_cn: require("../assets/images/el3-bg.jpg"),
          banner_en: require("../assets/images/el3-bg.jpg"),
          description_cn: "工作室的第三部作品，这是一款战棋类回合制RPG，拥有比较长的剧情和周目设定。",
          description_en: "The studio's third work, this is a wargame turn-based RPG with a relatively long story and weekly settings.",
          appstoreKey: "https://itunes.apple.com/app/id1304819307"
        },
        {
          name_cn: "元素纪元",
          name_en: "Elemental Ara",
          banner_cn: require("../assets/images/el4-bg.jpg"),
          banner_en: require("../assets/images/el4-bg.jpg"),
          description_cn: "工作室的第四部作品，这是一款战棋类回合制RPG，拥有很长的剧情和多种培养系统。",
          description_en: "The studio's fourth work, this is a wargame turn-based RPG with a long story and a variety of training systems.",
          appstoreKey: "https://itunes.apple.com/app/id1444454618"
        },
        {
          name_cn: "秘境传说-永恒地下城",
          name_en: "MagicDungeon",
          banner_cn: require("../assets/images/el8-bg.jpg"),
          banner_en: require("../assets/images/el8-bg.jpg"),
          description_cn: "工作室美术大神的单独作品，是一款经典的ARPG打怪游戏。玩家之间可以互动，非常适合养老。",
          description_en: "The solo work of Studio Art God is a classic ARPG monster fighting game. Players can interact with each other.",
          appstoreKey: "https://itunes.apple.com/app/id1594057181",
          androidKey_cn: "https://share.weiyun.com/TdEV5EH3",
          androidKey_en: "https://play.google.com/store/apps/details?id=com.njsl.el8",
        },
        {
          name_cn: "小镇传说",
          name_en: "TheTown",
          banner_cn: require("../assets/images/el7-bg.png"),
          banner_en: require("../assets/images/el7-bg.png"),
          description_cn: "工作室美术大神的单独作品，是一款经典的ARPG单机打怪游戏，无需互联网。",
          description_en: "The solo work of Studio Art Master is a classic ARPG single-player monster game that does not require the Internet.",
          androidKey_cn: "https://share.weiyun.com/zg9f9RWK",
          androidKey_en: "https://play.google.com/store/apps/details?id=com.njsl.el7",
        },
        {
          name_cn: "骑士迷城",
          name_en: "KnightMaze",
          banner_cn: require("../assets/images/doc1-banner.jpg"),
          banner_en: require("../assets/images/doc1-banner.jpg"),
          description_cn: "工作室策划大神的作品，这是一款战斗类型的消消乐闯关休闲游戏。",
          description_en: "The studio curates the work of the Great God, which is a casual game of the combat genre.",
          appstoreKey: "https://itunes.apple.com/app/id1606494647"
        },
        {
          name_cn: "风云刀客",
          name_en: "Cloud Swordsman",
          banner_cn: require("../assets/images/el9-banner.jpg"),
          banner_en: require("../assets/images/el9-banner.jpg"),
          description_cn: "工作室最新的武侠单机项目，目前还在开发当中，需要大家的支持。",
          description_en: "The studio's latest martial arts stand-alone project is still under development and needs everyone's support.",
        }
      ],
      keys: {
        NAME: "name",
        BANNER: "banner",
        DESCRIPTION: "description",
        IOSBTNSRC: "iosbtnSrc",
        GOOGLEBTNSRC: "googlebtnSrc",
        WELCOME: "welcome",
        BOTTOM_1: "bottom_1",
        BOTTOM_2: "bottom_2",
        ANDROIDKEY: "androidKey",
        MOBILE: enums.deviceType.MOBILE,
        PC: enums.deviceType.PC,
      },
      multiStaticWord: {
        welcome_en: "Welcome to  ʕ•ﻌ•ʔ",
        welcome_cn: "欢迎光临  ʕ•ﻌ•ʔ",
        bottom_1_cn: "因为热爱，所以游戏，欢迎各路大佬投资合作，工作室会做出您满意的作品。",
        bottom_1_en: "Because of love, so the game, welcome all kinds of big guys to invest and cooperate, the studio will make your satisfactory work.",
        bottom_2_cn: "抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。",
        bottom_2_en: "Boycott bad games and refuse pirated games. Pay attention to self-protection and beware of being deceived. Moderate games are good for the brain, and addiction to games hurts the body. Arrange your time reasonably and enjoy a healthy life.",
      },
      role_1: require("../assets/images/role_1.gif"),
      role_2: require("../assets/images/role_2.gif"),
      role_3: require("../assets/images/role_3.gif"),
      role_4: require("../assets/images/role_4.gif"),
      initleft_1: 400,
      initleft_2: 750,
      initleft_3: 80,
      initleft_4: 0,
      selectlanguage: [
        { id: enums.language.cn, name: "简体中文" },
        { id: enums.language.en, name: "English" }
      ],
      gameboxsrc: {
        iosbtnSrc_cn: require("../assets/images/btn_ios_download_cn.webp"),
        iosbtnSrc_en: require("../assets/images/btn_ios_download_en.png"),
        googlebtnSrc_cn: require("../assets/images/btn_android_download_cn.webp"),
        googlebtnSrc_en: require("../assets/images/btn_android_download_en.png")
      },
      language: 'en',
    }
  },
  methods: {
    getMultiValue(data, key) {
      //TODO
      console.log(this.language);
      return data[key + '_' + this.language];
    },
    onselect(event) {
      this.language = event.target.value;
    }
  },
  mounted() {
    const { appContext: { config: { globalProperties } } } = getCurrentInstance();
    this.language = globalProperties.$languge;

    window.onload = function () {
      document.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      })
      document.addEventListener('gesturestart', function (event) {
        event.preventDefault()
      })
    }
  },

  components: { GameBox },


}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#app {
  width: 9rem
}

.log_s {
  position: relative;
  top: .1rem;
  width: 0.8rem;
}

.topbg {
  position: sticky;
  top: 0rem;
  z-index: 99999;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0rem 1px .1rem #0000004f;
}

.topbg h1 {
  font-size: 12pt;
  position: absolute;
  top: .1rem;
  left: .9rem
}

.topbg a {
  font-size: 11pt;
  position: absolute;
  top: .45rem;
  left: .9rem
}

.topbg select {
  position: relative;
  top: .15rem;
  right: .15rem;
  width: 2rem;
  height: .7rem;
  float: right;
}

.studiobanner {
  position: relative;
  text-align: left;
  z-index: -99;
}

.cards {
  width: 1rem;
}

.copyright {
  background: #1b1d1f;
  color: rgba(255, 255, 255, 0.568);
}

.copyright img {
  position: relative;
  top: .03rem;
  left: 1.2rem;
  width: 8%;
  height: 10%;
}

.copyright a {
  position: relative;
  top: -0.08rem;
  left: 1.3rem;
  color: #86eb29;
  font-size: 15pt;
  font-weight: bold;
}

.copyright p {
  padding-left: .2rem;
  padding-right: .1rem;
  font-size: 8pt;
}

.bottomlogo {
  background: #1b1d1f;
  position: relative;
  text-align: center;
  width: 100%;
  padding-bottom: .2rem;
}

.bottomlogo img {
  width: 15%;
  padding-left: .2rem;
}

#unity {
  width: 15%;
  position: relative;
  top: .16rem;
}

#oc {
  width: 20%;
  position: relative;
  top: .17rem;
}

#java {
  width: 15%;

}

#csharp {
  width: 7%;
  position: relative;
  bottom: .1rem;
}

#dragonbones {
  width: 6%;
  position: relative;
  bottom: .2rem;
}

#procreate {
  width: 6.5%;
  position: relative;
  bottom: .18rem;
}

#python {
  width: 17%;
  position: relative;
  bottom: .18rem;
}

#springboot {
  width: 20%;
}

#github {
  width: 8%;
  position: relative;
  bottom: .12rem;
}
</style>
