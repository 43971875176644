<template>
  <div class="topbanner">
    <div class="topbg">
      <img class="log_s" src="../assets/tag_studioLogo.png">
      <h1>Chowchow&CWGame Studio</h1>
      <a>{{ this.getMultiValue(multiStaticWord, keys.WELCOME) }}</a>
      <select class="selectbox" @change="onselect($event)">
        <option v-for="item in selectlanguage" v-bind:key="item.id" v-bind:value="item.id" v-text="item.name"></option>
      </select>
    </div>
  </div>

  <RoleBox :roleSrc=role_1 :soleId="1" :initleft="initleft_1"></RoleBox>
  <RoleBox :roleSrc=role_2 :soleId="2" :initleft="initleft_2"></RoleBox>
  <RoleBox :roleSrc=role_3 :soleId="3" :initleft="initleft_3"></RoleBox>
  <RoleBox :roleSrc=role_4 :soleId="4" :initleft="initleft_4"></RoleBox>
  <div class="studiobanner"> <img src="../assets/login_banner_studioLogo.png"></div>
  <div class="gamegrid">
    <div class="cards">
      <div v-for="(item, index) in games" :key="index">
        <GameBox :title="this.getMultiValue(item, keys.NAME)" :banner="this.getMultiValue(item, keys.BANNER)"
          :description="this.getMultiValue(item, keys.DESCRIPTION)" :appstoreKey=item.appstoreKey
          :iosbtnSrc="this.getMultiValue(gameboxsrc, keys.IOSBTNSRC)"
          :googlebtnSrc="this.getMultiValue(gameboxsrc, keys.GOOGLEBTNSRC)"
          :androidKey="this.getMultiValue(item, keys.ANDROIDKEY)" :deviceType="keys.PC" :id="index">
        </GameBox>
      </div>
    </div>
  </div>
  <div id="NIE-copyRight">
    <div class="nie-copyright-new-inner">
      <div class="nie-cn-part2">
        <img class="mailicon" src="~@/assets/images/common_tag_mail.png">
        <a target="_blank">&nbsp; dsyxabc@gmail.com</a>
        <p class="p-bottom">{{ this.getMultiValue(multiStaticWord, keys.BOTTOM_1) }}</p>
        <p class="p-bottom">{{ this.getMultiValue(multiStaticWord, keys.BOTTOM_2) }}</p>
        <p class="p-bottom">Copyright Nanjing Sailai Network Technology Co., Ltd ©2014-2023</p>
      </div>
    </div>
  </div>
</template>

<script>
import GameBox from '../components/GameBox.vue';
import RoleBox from '../components/RoleBox.vue';
import { getCurrentInstance } from "vue";
import { enums } from '@/global';

export default {
  name: "CWGames&ChowChow",
  props: {
    msg: String
  },
  data() {
    return {
      games: [
        {
          name_cn: "永恒传说-问世篇",
          name_en: "Eternal legend",
          banner_cn: require("../assets/images/el1-bg.jpg"),
          banner_en: require("../assets/images/el1-bg.jpg"),
          description_cn: "工作室早年的第一部作品，非常经典的单机回合制剧情向RPG，在武侠的世界放飞自我吧。",
          description_en: "The studio's early works, a very classic single-player turn-based plot to RPG, let yourself go in the world of martial arts.",
          appstoreKey: "itms-apps://itunes.apple.com/app/id949814335",
          androidKey_cn: "https://share.weiyun.com/J4i94BuN",
          androidKey_en: "https://play.google.com/store/apps/details?id=com.njsl.el1",
        },
        {
          name_cn: "永生劫-降魔篇",
          name_en: "The disaster of immortal",
          banner_cn: require("../assets/images/el2-bg.jpg"),
          banner_en: require("../assets/images/el2-bg.jpg"),
          description_cn: "工作室的第二部作品，这是一款仙侠风格的游戏，同样也是经典的回合制剧情向RPG。",
          description_en: "The studio's second work, this is a fairytale-style game, which is also a classic turn-based story RPG.",
          appstoreKey: "itms-apps://itunes.apple.com/app/id1079800951",
          androidKey_cn: "https://play.google.com/store/apps/details?id=com.njsl.el2",
          androidKey_en: "https://play.google.com/store/apps/details?id=com.njsl.el2",
        },
        {
          name_cn: "冒险之巅",
          name_en: "Fall of the divinities",
          banner_cn: require("../assets/images/el3-bg.jpg"),
          banner_en: require("../assets/images/el3-bg.jpg"),
          description_cn: "工作室的第三部作品，这是一款战棋类回合制RPG，拥有比较长的剧情和周目设定。",
          description_en: "The studio's third work, this is a wargame turn-based RPG with a relatively long story and weekly settings.",
          appstoreKey: "itms-apps://itunes.apple.com/app/id1304819307"
        },
        {
          name_cn: "元素纪元",
          name_en: "Elemental Ara",
          banner_cn: require("../assets/images/el4-bg.jpg"),
          banner_en: require("../assets/images/el4-bg.jpg"),
          description_cn: "工作室的第四部作品，这是一款战棋类回合制RPG，拥有很长的剧情和多种培养系统。",
          description_en: "The studio's fourth work, this is a wargame turn-based RPG with a long story and a variety of training systems.",
          appstoreKey: "itms-apps://itunes.apple.com/app/id1444454618"
        },
        {
          name_cn: "秘境传说-永恒地下城",
          name_en: "MagicDungeon",
          banner_cn: require("../assets/images/el8-bg.jpg"),
          banner_en: require("../assets/images/el8-bg.jpg"),
          description_cn: "工作室美术大神的单独作品，是一款经典的ARPG打怪游戏。玩家之间可以互动。",
          description_en: "The solo work of Studio Art God is a classic ARPG monster fighting game. Players can interact with each other.",
          appstoreKey: "itms-apps://itunes.apple.com/app/id1594057181",
          androidKey_cn: "https://share.weiyun.com/TdEV5EH3",
          androidKey_en: "https://play.google.com/store/apps/details?id=com.njsl.el8",
        },
        {
          name_cn: "风云刀客",
          name_en: "Cloud Swordsman",
          banner_cn: require("../assets/images/el9-banner.jpg"),
          banner_en: require("../assets/images/el9-banner.jpg"),
          description_cn: "工作室最新的武侠单机项目，目前还在开发当中，需要大家的支持。",
          description_en: "The studio's latest martial arts stand-alone project is still under development and needs everyone's support.",
        },
        {
          name_cn: "小镇传说",
          name_en: "TheTown",
          banner_cn: require("../assets/images/el7-bg.png"),
          banner_en: require("../assets/images/el7-bg.png"),
          description_cn: "工作室美术大神的单独作品，是一款经典的ARPG单机打怪游戏，无需互联网。",
          description_en: "The solo work of Studio Art Master is a classic ARPG single-player monster game that does not require the Internet.",
          androidKey_cn: "https://share.weiyun.com/zg9f9RWK",
          androidKey_en: "https://play.google.com/store/apps/details?id=com.njsl.el7",
        },
        {
          name_cn: "骑士迷城",
          name_en: "KnightMaze",
          banner_cn: require("../assets/images/doc1-banner.jpg"),
          banner_en: require("../assets/images/doc1-banner.jpg"),
          description_cn: "工作室策划大神的作品，这是一款战斗类型的消消乐闯关休闲游戏。",
          description_en: "The studio curates the work of the Great God, which is a casual game of the combat genre.",
          appstoreKey: "itms-apps://itunes.apple.com/app/id1606494647"
        }
      ],
      keys: {
        NAME: "name",
        BANNER: "banner",
        DESCRIPTION: "description",
        IOSBTNSRC: "iosbtnSrc",
        GOOGLEBTNSRC: "googlebtnSrc",
        WELCOME: "welcome",
        BOTTOM_1: "bottom_1",
        BOTTOM_2: "bottom_2",
        ANDROIDKEY: "androidKey",
        PC: enums.deviceType.PC,
      },
      multiStaticWord: {
        welcome_en: "Welcome to  ʕ•ﻌ•ʔ",
        welcome_cn: "欢迎光临  ʕ•ﻌ•ʔ",
        bottom_1_cn: "因为热爱，所以游戏，欢迎各路大佬投资合作，工作室会做出您满意的作品。",
        bottom_1_en: "Because of love, so the game, welcome all kinds of big guys to invest and cooperate, the studio will make your satisfactory work.",
        bottom_2_cn: "健康游戏忠告：抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。",
        bottom_2_en: "Healthy gaming advice: Boycott bad games and refuse pirated games. Pay attention to self-protection and beware of being deceived. Moderate games are good for the brain, and addiction to games hurts the body. Arrange your time reasonably and enjoy a healthy life.",
      },
      role_1: require("../assets/images/role_1.gif"),
      role_2: require("../assets/images/role_2.gif"),
      role_3: require("../assets/images/role_3.gif"),
      role_4: require("../assets/images/role_4.gif"),
      initleft_1: 400,
      initleft_2: 350,
      initleft_3: 80,
      initleft_4: 0,
      selectlanguage: [
        { id: enums.language.cn, name: "简体中文" },
        { id: enums.language.en, name: "English" }
      ],
      gameboxsrc: {
        iosbtnSrc_cn: require("../assets/images/btn_ios_download_cn.webp"),
        iosbtnSrc_en: require("../assets/images/btn_ios_download_en.png"),
        googlebtnSrc_cn: require("../assets/images/btn_android_download_cn.webp"),
        googlebtnSrc_en: require("../assets/images/btn_android_download_en.png")
      },
      language: 'en',
    }
  },
  methods: {
    getMultiValue(data, key) {
      //TODO
      console.log(this.language);
      return data[key + '_' + this.language];
    },
    onselect(event) {
      this.language = event.target.value;
    }
  },
  mounted() {
    const { appContext: { config: { globalProperties } } } = getCurrentInstance();
    this.language = globalProperties.$languge;
  },

  components: { GameBox, RoleBox },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cards {
  display: grid;
  grid-template-columns: repeat(3, 350px);
  grid-gap: 20px;
  justify-content: center;
  align-content: center;
  position: relative;
  bottom: 10px;
}

.gamegrid {
  margin-top: -27px;
  background-color: #f2f1f1;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  min-width: 1260px;
}

@media only screen and (max-width: 720px) {
  .cards {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  font-size: 12pt;
  color: #81ff47c9;
}

.mailicon {
  width: 3%;
  height: 3%;
}

.p-bottom {
  margin: 0px;
}

.nie-copyright-new-inner {
  width: 1024px;
  text-align: left;
  margin: 0 auto;
  padding: 0px 0 20px;
  font-size: 12px;
  line-height: 20px;
}

.nie-copyright-new-inner .nie-cn-logo {
  display: inline-block;
  vertical-align: middle;
  height: 48px;
  min-width: 300px
}

.nie-copyright-new-inner .nie-cn-logo a {
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
}

.nie-copyright-new-inner .nie-cn-logo a.a0 img {
  padding-right: 0
}

.nie-copyright-new-inner .nie-cn-logo img {
  padding-right: 15px;
  padding-top: 5px;
  border: 0;
  vertical-align: middle;
}

.nie-copyright-new-inner .nie-cn-nav {
  display: inline-block;
  vertical-align: top;
  position: relative;
  z-index: 1;
}

.nie-copyright-new-inner .nie-cn-nav i {
  font-style: normal
}

.nie-copyright-new-inner .nie-cn-nav i.nie-cn-nav-i2 {
  height: 28px;
  line-height: 28px;
  text-align: left;
}

.nie-copyright-new-inner .nie-cn-nav a {
  text-decoration: none
}

.nie-copyright-new-inner .nie-cn-nav a:hover {
  text-decoration: underline
}

.nie-copyright-new-inner .nie-cn-nav .nie-cn-reportcenter {
  padding-left: 18px;
  font-style: normal;
}

.nie-copyright-new-inner .nie-cn-nav a.nie-cn-guardianship {
  display: inline-block;
  color: #fff;
  background-color: #610000;
  position: absolute;
  z-index: 2;
  right: 0;
  height: 26px;
  line-height: 26px;
  padding: 0 3px;
  top: 21px
}

#NIE-copyRight {
  background: #272a2c;
  min-width: 1260px;
  color: #fff;
}

#NIE-copyRight a.nie-cn-guardianship {
  color: #fff;
}

.nie-copyright-new-inner .nie-cn-part2 {
  padding-top: 10px;
  line-height: 24px
}

.nie-copyright-new-inner .nie-cn-part2 a {
  text-decoration: none;
  display: inline-block;
  vertical-align: top
}

.nie-copyright-new-inner .nie-cn-part2 a:hover {
  text-decoration: underline
}

.nie-copyright-new-inner .nie-cn-part2 span {
  display: inline-block;
  vertical-align: top;
}

.nie-cn-part2 a img {
  vertical-align: top
}

.topbg h1 {
  float: left;
  position: relative;
  margin-left: 15px;
  margin-top: 2px;
  font-size: 12pt;
  font-weight: bold
}

.topbg a {
  float: left;
  position: absolute;
  margin-top: 20px;
  font-size: 10pt;
  color: #6b6b6b;
  left: 52px;
}

.log_s {
  width: 35px;
  height: 32px;
  float: left;
  position: relative;
  top: 3px;
  left: 5px;
}

.selectbox {
  width: 200px;
  height: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 15px;
  float: right;
}

.studiobanner {
  position: relative;
  top: -30px;
  text-align: center;
  z-index: -99;
  min-width: 1260px;
}

.topbanner {
  position: sticky;
  top: 0px;
  z-index: 99999;
  width: 100%;
  min-width: 1260px;
}

.topbg {
  height: 40px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 2px 2px 5px #0000004f;
  min-width: 1260px;
}
</style>
